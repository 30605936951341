<template>
    <div class="subscriber pl-4 pb-4">
        <v-row class="subscriber-title">
            <v-col cols="7" sm="4" md="6">
                <router-link :to="{name: 'otherUser.profile.viewSlug', params: {slug: subscription.creator_slug, from: $route.name}}">
                    {{ subscription.creator_name }}
                </router-link>
                <div v-if="subscription.type==='direct_messages'"
                     class="subscriber-subtitle">(Direct Message)</div>
            </v-col>
            <v-col cols="5" sm="8" md="6" class="red-orange--text d-flex align-self-end justify-center">
                {{ subscription.fixed_price }}
                {{ currency }}
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="8" sm="4" md="6">
                <v-row>
                    <span v-if="subscription.created_at" class="date-text pl-4">Start date:</span> <span
                    class="date-value pl-3">{{ (subscription.created_at) }}</span>
                </v-row>
                <v-row v-if="!subscription.cancelled">
                    <span v-if="subscription.renewal_at" class="date-text pl-4">Renewal date:</span> <span
                    class="date-value pl-3">{{ (subscription.renewal_at) }}</span>
                </v-row>
                <v-row v-if="subscription.cancelled">
                    <span v-if="subscription.expired_at" class="date-text pl-4">End date:</span> <span
                    class="date-value pl-3">{{ (subscription.expired_at) }}</span>
                </v-row>
            </v-col>
            <v-col v-if="subscription.is_expired"
                   cols="4"
                   class="red-orange--text d-flex align-self-end justify-center">
                <a class="red-orange--text" @click="handleClickButton(itemId)">{{ textButton }}</a>
            </v-col>
            <v-col v-if="!subscription.is_expired && !subscription.cancelled"
                   cols="4"
                   class="red-orange--text d-flex align-self-end justify-center">
                <a class="red-orange--text" @click="handleClickButton(itemId)">{{ textButton }}</a>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapMutations} from 'vuex'
import moment from "moment";
import {CURRENCY_SYMBOL} from "@/configs/constants";


export default {
    name: "ItemSubscription",
    data() {
        return {
            currency: CURRENCY_SYMBOL
        }
    },
    props: {
        subscription: {
            type: Object,
            required: true
        },
        textButton: {
            type: String,
            required: true
        },
        itemId: {
            type: Number,
            required: true
        },
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        format(date) {
            return moment(date).format('DD MMM YYYY')
        },
        handleClickButton(id) {
            this.$emit('clickButton', id)
        },
    },
}
</script>

<style scoped>
.subscriber-title {
    font-size: 1.7em;
}

.subscriber-subtitle {
    font-size: 0.6em;
}

.subscriber {
    width: 100%;
    background: rgba(237, 237, 237, 0.1);
    border-radius: 8px;
}

.red-orange--text {
    color: #FD6014
}

.date-text {
    color: rgba(255, 255, 255, 0.5)
}
</style>
