<template>
    <setting-layout title="My Subscriptions" :back="{ name: 'user.own.profile.wallet' }">
        <v-container v-if="activeSubscribers.length" class="pt-2">
            {{ $t("dialogs.active") }}
            <v-row v-for="(val, i) in activeSubscribers" :key="i" class="mt-3">
                <item-subscription
                    :subscription="val"
                    :itemId="i"
                    :textButton="$t('dialogs.cancel')"
                    @clickButton="handleCancel"
                />
            </v-row>
        </v-container>
        <v-container v-if="expiredSubscribers.length" class="pt-2">
            {{ $t("dialogs.expired") }}
            <v-row v-for="(val, i) in expiredSubscribers" :key="i" class="mt-3">
                <item-subscription
                    :subscription="val"
                    :itemId="i"
                    :textButton="$t('dialogs.renew')"
                    @clickButton="handleRenew"
                />
            </v-row>
        </v-container>
        <subscribe-dialog
            v-if="renewInfo"
            :price="currentSubscription.current_price"
            :subscription="currentSubscription"
            :subscription-bundles="
                currentSubscription.bundles || defaultBundles
            "
            @subscribe="handleSubscribe"
            @close="closeSubscribeInfo"
        />
        <unsubscribe-dialog
            v-if="cancelWarning"
            @unsubscribe="handleUnsubscribe"
            @close="closeUnsubscribeWarning"
        />
        <insufficient-funds-dialog
            v-if="insufficientFundsError"
            @close="insufficientFundsError = false"
        />
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import ItemSubscription from "@/components/app/profile/ItemSubscription";
import { mapActions, mapMutations } from "vuex";
import SubscribeDialog from "@/components/app/dialogs/SubscribeDialog";
import UnsubscribeDialog from "@/components/app/dialogs/UnsubscribeDialog";
import InsufficientFundsDialog from "@/components/app/dialogs/InsufficientFundsDialog.vue";
import { INSUFFICIENT_FUNDS_ERROR } from "@/configs/constants";

export default {
    name: "MySubscriptions",
    data() {
        return {
            errors: {},
            currentSubscription: {},
            expiredSubscribers: {},
            activeSubscribers: {},
            renewInfo: false,
            cancelWarning: false,
            insufficientFundsError: false,
            defaultBundles: [
                {
                    isEnabled: true,
                    period: 3,
                    discount: 15,
                },
                {
                    isEnabled: true,
                    period: 6,
                    discount: 25,
                },
                {
                    isEnabled: true,
                    period: 12,
                    discount: 50,
                },
            ],
        };
    },
    methods: {
        ...mapActions(["subscribe", "unsubscribe"]),
        ...mapMutations(["showSnackBar"]),
        handleRenew(id) {
            this.renewInfo = true;
            this.currentSubscription = this.expiredSubscribers[id];
        },
        handleCancel(id) {
            this.cancelWarning = true;
            this.currentSubscription = this.activeSubscribers[id];
        },
        closeUnsubscribeWarning() {
            this.cancelWarning = false;
        },
        closeSubscribeInfo() {
            this.renewInfo = false;
        },
        async handleSubscribe(bundleIndex) {
            this.renewInfo = false;
            const userId = this.currentSubscription.creator_user_id;
            if (this.currentSubscription.type === "direct_messages") {
                axios
                    .post(`/api/chat/buy/${userId}`)
                    .then(() => this.showSnackBar(this.$t("messages.success")))
                    .catch(({ response }) => {
                        if (response.status === 400) {
                            if (
                                response.data.err_key ===
                                INSUFFICIENT_FUNDS_ERROR
                            ) {
                                this.insufficientFundsError = true;
                                return;
                            }
                            return;
                        }
                        this.showSnackBar(
                            this.$t("messages.something_went_wrong")
                        );
                    })
                    .finally(() => this.fetchSubscription());
                return;
            }
            if (this.currentSubscription.type === "user_subscription") {
                this.subscribe({
                    id: userId,
                    bundle:
                        !bundleIndex && bundleIndex !== 0
                            ? null
                            : this.currentSubscription.bundles
                            ? this.currentSubscription.bundles[bundleIndex]
                            : this.defaultBundles[bundleIndex],
                })
                    .then((res) => {
                        if (typeof res.redirectUrl !== "undefined") {
                            this.$window.location = res.redirectUrl;
                            return;
                        }
                        this.showSnackBar(this.$t("messages.success"));
                    })
                    .catch(() =>
                        this.showSnackBar(
                            this.$t("messages.something_went_wrong")
                        )
                    )
                    .finally(() => this.fetchSubscription());
                return;
            }
            this.showSnackBar(this.$t("messages.something_went_wrong"));
        },
        async handleUnsubscribe(event) {
            this.cancelWarning = false;
            if (this.currentSubscription.type === "direct_messages") {
                const userId = this.currentSubscription.creator_user_id;
                axios
                    .post(`/api/chat/unsubscribe/${userId}`)
                    .catch(() =>
                        this.showSnackBar(
                            this.$t("messages.something_went_wrong")
                        )
                    )
                    .finally(() => this.fetchSubscription());
                return;
            }
            if (this.currentSubscription.type === "user_subscription") {
                await this.unsubscribe({
                    id: this.currentSubscription.creator_user_id,
                    reason: event.reason,
                })
                    .then((res) => {
                        if (typeof res.redirectUrl !== "undefined") {
                            this.$window.location = res.redirectUrl;
                            return;
                        }
                        this.showSnackBar(this.$t("messages.success"));
                    })
                    .catch(() =>
                        this.showSnackBar(
                            this.$t("messages.something_went_wrong")
                        )
                    )
                    .finally(() => this.fetchSubscription());
                return;
            }
            this.showSnackBar(this.$t("messages.something_went_wrong"));
        },
        fetchSubscription() {
            axios
                .get("/api/account/settings/subscription/not-expired", { hideLoader: true })
                .then((res) => {
                    this.activeSubscribers = res.data;
                });
            axios
                .get("/api/account/settings/subscription/expired", { hideLoader: true })
                .then((res) => {
                    this.expiredSubscribers = res.data;
                });
        },
    },
    created() {
        this.fetchSubscription();
    },

    components: {
        InsufficientFundsDialog,
        UnsubscribeDialog,
        SubscribeDialog,
        SettingLayout,
        ItemSubscription,
    },
};
</script>
