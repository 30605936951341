<template>
    <app-transition>
        <Dialog @close="handleClose">
            <v-row>
                <v-col>
                    <h2 class="text-center">
                        <b>{{ $t("dialogs.subscription") }}</b>
                    </h2>
                    <p class="text-center my-4">
                        {{ $t("dialogs.subscription_include") }}
                    </p>

                    <div>
                        <app-icon size="14" icon="check" class="mx-1" />
                        {{ $t("dialogs.support_model") }}
                    </div>
                    <div>
                        <app-icon size="14" icon="check" class="mx-1" />
                        {{ $t("dialogs.unlock_all_stories") }}
                    </div>
                    <div>
                        <app-icon size="14" icon="check" class="mx-1" />
                        {{ $t("dialogs.unlimited_direct_messages") }}
                    </div>
                    <div
                        v-for="(item, index) in subscription.benefits_list"
                        :key="index"
                        style="display: flex; align-items: center"
                    >
                        <IconPremium class="mr-1" />
                        {{ item }}
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn
                        outlined
                        block
                        height="50"
                        class="mt-2 flex-column"
                        @click="handleSubscribe"
                    >
                        <div>
                            <b>{{ $t("dialogs.subscribe") }}</b>
                        </div>
                        <div>
                            {{ price }}{{ currency }} /
                            {{ $t("dialogs.month") }}
                        </div>
                    </v-btn>

                    <template v-if="price > 0">
                        <div
                            style="
                                width: 100%;
                                height: 1px;
                                background: rgba(255, 255, 255, 0.2);
                            "
                            class="my-6"
                        ></div>

                        <v-btn
                            v-for="(
                                bundle, bundleIndex
                            ) in subscriptionBundles.filter(
                                (bundle) => bundle.isEnabled
                            )"
                            :key="bundle.period"
                            outlined
                            block
                            height="50"
                            class="mt-3 flex-column"
                            @click="handleSubscribe(bundleIndex)"
                        >
                            <div
                                style="
                                    display: flex;
                                    justify-content: space-between;
                                "
                            >
                                <div class="mr-8">
                                    <b>{{ bundle.period }} Months</b> ({{
                                        bundle.discount
                                    }}%
                                    <span style="text-transform: lowercase"
                                        >off</span
                                    >)
                                </div>

                                <div>
                                    {{ currency
                                    }}{{
                                        price * bundle.period -
                                        (price *
                                            bundle.period *
                                            bundle.discount) /
                                            100
                                    }}
                                    <span style="text-transform: lowercase"
                                        >total</span
                                    >
                                </div>
                            </div>
                        </v-btn>
                    </template>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>

<script>
import Dialog from "@/components/app/form/Dialog";
import { CURRENCY_SYMBOL } from "@/configs/constants";
import IconPremium from "@/assets/images/icons/premium.svg?inline";

export default {
    name: "subscribeDialog",
    data: () => ({
        currency: CURRENCY_SYMBOL,
    }),
    props: {
        price: {
            type: [String, Number],
            default: "0",
        },
        subscription: {
            type: Object,
            default: {
                benefits_list: [],
            },
        },
        subscriptionBundles: {
            type: Array,
        },
    },
    methods: {
        handleClose() {
            this.$emit("close");
        },
        handleSubscribe(bundleIndex = null) {
            this.$emit("subscribe", bundleIndex);
        },
    },
    components: {
        Dialog,
        IconPremium,
    },
};
</script>

<style scoped lang="scss">
@import "@/sass/modules/_variables";

ul.style-dashed {
    list-style: none;
}

ul.style-dashed li:before {
    content: "—";
    position: relative;
    left: -5px;
}
</style>
<style>
button.flex-column .v-btn__content {
    flex-direction: column !important;
}
</style>
