<template>
    <app-transition>
        <Dialog @close="handleClose">
            <div class="text-center text-h6">
                {{ $t("dialogs.cancel_subscription") }}
            </div>
            <div class="text-center mt-2">
                {{ $t("dialogs.cancel_subscription_desc") }}
            </div>

            <select v-model="reason" class="mt-4">
                <option v-for="(option, index) in REASON_OPTIONS" :key="index">
                    {{ option }}
                </option>
            </select>

            <div
                class="mt-8 row no-wrap justify-space-between"
                style="width: 100%"
            >
                <div style="width: 48%">
                    <v-btn
                        outlined
                        block
                        height="50"
                        @click="handleUnsubscribe"
                        >{{ $t("dialogs.yes") }}</v-btn
                    >
                </div>
                <div style="width: 48%">
                    <v-btn outlined block height="50" @click="handleClose">
                        {{ $t("dialogs.no") }}
                    </v-btn>
                </div>
            </div>
        </Dialog>
    </app-transition>
</template>

<script>
import Dialog from "@/components/app/form/Dialog";

export default {
    name: "unsubscribeDialog",
    data: () => ({
        reason: "No Specific Reason",
        REASON_OPTIONS: [
            "No Specific Reason",
            "Low Posting Frequency",
            "Private Messages not reply",
            "Subscription price too high",
        ],
    }),
    methods: {
        handleClose() {
            this.$emit("close");
        },
        handleUnsubscribe() {
            this.$emit("unsubscribe", { reason: this.reason });
        },
    },
    components: {
        Dialog,
    },
};
</script>

<style scoped>
select {
    background: rgba(255, 255, 255, 0.25);
    border-radius: 8px;
    padding: 8px 16px;
    color: white;
    text-align: center;
}
</style>
